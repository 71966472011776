<template>
  <PSection wari-wari-game-info theme="white">
    <div class="contents-holder">
      <div class="left-holder">
        <WildRiftRewards />
      </div>
      <div class="right-holder">
        <WildRiftResult />
      </div>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'WariWariGameInfo',
  components: {
    WildRiftRewards: () => import('@/views/components/gameHome/wr/gameInfo/detailSection/WariWariRewards.vue'),
    WildRiftResult: () => import('@/views/components/gameHome/wr/gameInfo/detailSection/WariWariResult.vue'),
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wari-wari-game-info] { .bgc(#141414); .p(47, 20, 60); .rel;
  @media (@tl-up) {
    .p(60, 58);
  }
  @media (@ds-up) {
    .p(90, 0);
    .contents-holder {.ib-list; .w(@screen-ds-min); .mh-c;
      > * {.-box;}
    }
    .left-holder { .pr(80); .w(640); .rel;
      &:after {.cnt; .rt(0, 0); .wh(1, 100%); .bgc(rgba(255, 255, 255, .3)); .abs;}
    }
    .right-holder { .pl(80); .w(620); }
  }
}
</style>
