<template>
  <ImageAsset wari-wari-main-symbol v-que="{animation: 'jello', delay: 1000, duration: 750}" url="/img/asset/logo/wari_wari_logo.svg" bg-color="" />
</template>

<script>
import que from '@/directives/que';
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'WariWariMainSymbol',
  directives: { que },
  components: { ImageAsset },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[wari-wari-main-symbol] { .wh(236, 182);
  @media (@tl-up) {
    .wh(328, 253);
  }
  @media (@ds-up) {
  }
}
</style>
