<template>
  <ImageAsset wari-wari-hero v-prx:intro="{ r: [4, 8], o: [0, 1] }" bg-color=""
    url="/img/event/wariwari/landing/Image/WR_main_bg.jpg" />
</template>

<script>
import ImageAsset from '@/views/components/common/ImageAsset.vue';

export default {
  name: 'WariWariHero',
  components: { ImageAsset },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[wari-wari-hero] { .block; .wh(100vw, 100%); .abs; z-index: 1; .lt(0, 0); z-index: 1; .tc; .br(0);
  > img { .max-w(100%); .min-h(642); }
  @media (@tl-up) {
    .h(972);
    > img { .max-w(1945); }
  }
  @media (@ds-up) {
    > img { .max-w(1945); }
  }
}
</style>
