<template>
  <ul wari-wari-detail>
    <li class="league-intro">
      <WariWariMainSymbol />
      <div class="intro-wrapper">
        <h1>
          <span>총상금 2,500만원과</span>
          <span>한국대표 선발전</span>
          진출 시드권을 잡아라!
        </h1>
        <div class="content-wrapper">
          <span class="badge grey">예선</span>
          <span class="date-info">7월31일(토) ~ 8월3일(화)</span>
        </div>
        <div class="content-wrapper">
          <span class="badge grey">본선</span>
          <span class="date-info">8월5일(목)</span>
        </div>
        <div class="content-wrapper">
          <span class="badge red">결선</span>
          <span class="date-info">8월9일(월) ~ 8월24일(화)</span>
        </div>
      </div>
    </li>
    <li class="league-progress">
      <article>
        <div class="outer gold"><h2>예선</h2></div>
        <div class="p-container">
          <p class="p-first">4일간 매일 토너먼트(Bo1)를 진행하여</p>
          <p class="p-second"><strong>매일 4팀 선발</strong><em>(4일X4팀 = 16팀 선발)</em></p>
        </div>
      </article>
      <ImageAsset url="/img/pages/attach/direction_gold.png" bg-color="" />
      <article>
        <div class="outer gold"><h2>본선</h2></div>
        <div class="p-container">
          <p class="p-first">예선 16팀이 토너먼트(Bo3)를 진행하여</p>
          <p class="p-second"><strong>최종 14팀 선발</strong></p>
        </div>
      </article>
      <ImageAsset url="/img/pages/attach/direction_gold.png" bg-color="" />
      <article>
        <div class="outer blue"><h2>결선</h2></div>
        <div class="p-container">
          <p class="p-first">본선 14팀 + 스트리머 2팀 16강 진행하여</p>
          <p class="p-second"><strong>상위 5팀 한국대표 선발전 진출</strong></p>
        </div>
      </article>
      <button class="btn-go-landing" @click="toggleGoLanding">
        대회 안내 바로가기
      </button>
    </li>
  </ul>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import ImageAsset from '@/views/components/common/ImageAsset.vue';
import WariWariMainSymbol from '@/views/components/gameHome/wr/WariWariMainSymbol.vue';

export default {
  name: 'WariWariDetail',
  mixins: [gtag],
  components: { ImageAsset, WariWariMainSymbol },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    toggleGoLanding() {
      this.trackEvent(`Main_landing_btn`, 'click', `${this.upRouteGameId}`);
      this.$router.push({ name: 'WariwariLanding' });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[wari-wari-detail] { .rel; z-index: 2; .flex; .flex-ai; justify-content: space-around; flex-wrap: wrap; .mt(52);
  .league-intro { .rel; .wh(268, 448); .m(0, 10, 40);
    [wari-wari-main-symbol] { .ml(21); }
    .intro-wrapper {
      h1 { font-family: 'gmarket', sans-serif; .c(#fff); .bold; .ls(-7%); .fs(28, 36); .m(20, 0); .tc;
        > span {
          &:nth-of-type(1) { .block; }
          &:nth-of-type(2) { .block; }
        }
      }
      .content-wrapper {
        .badge { font-family: @spoqa-font; .bold; .ib; .w(64); .br(20); .vam; .tc; .fs(14, 32); .semi-bold;
          &.grey { .bgc(#5b5c61); }
          &.red { .bgc(#d13639); }
        }
        .date-info { font-family: @spoqa-font; .c(#fff); .fs(14); .regular; .ml(16); }
      }
      .content-wrapper + .content-wrapper { .mt(12); }
    }
  }
  .league-progress { .rel; .bgc(rgba(36, 38, 44, 0.8)); .br(8); .-box; .p(32, 23); .wh(335, 414); .m(0, 10);
    > article { .flex; .flex-ai;
      .outer { .br(50%); .p(5); .-box; .wh(68, 68); .ib;
        > h2 { font-family: 'gmarket', sans-serif; .tc; .vam; .bold; .wh(56); .lh(56); .ls(-7%);.fs(16); }
      }
      .p-container { .ib; .ml(20);
        .p-first { .font-g; .c(rgba(255, 255, 255, 0.6)); .fs(12); .nowrap; .mb(6); .ls(-7%); }
        .p-second {
          > strong { .fs(20); .font-g; .ls(-7%); }
          > em { font-family: 'gmarket', sans-serif; .c(#a38e40); .ls(-7%); .light; .block; .fs(12); .mt(6); }
        }
      }
      &:nth-of-type(1) { .mb(32); }
      &:nth-of-type(2) { .mb(34); }
    }
    .gold { .-a(#a38e40, 1);
      > h2 { .c(#a38e40); .br(50%); .-a(#a38e40, 2); }
    ;
    }
    .blue { .-a(#69c6fa, 1);
      > h2 { .c(#69c6fa); .br(50%); .-a(#69c6fa, 2); }
    ;
    }
    .btn-go-landing { .wh(142, 48); .abs; .rt(96, 334); .bgc(#375be7); .c(white); .br(8); .wh(142, 48); .fs(14); }
    [image-asset] { .abs; .wh(11, 16);
      &:nth-of-type(1) { .lt(51, 108); }
      &:nth-of-type(2) { .lt(51, 208); }
    }
  }

  @media (@tl-up) {
    .mt(84);
    .league-intro { .wh(856, 254); .flex; .flex-ai; .mb(58);
      [wari-wari-main-symbol] { .mr(57); flex-shrink: 0; .ml(0); }
      .intro-wrapper {
        h1 { .mb(28); .tl; .ml(0);
          > span {
            &:nth-of-type(2) { .ib; .mr(7); }
          }
        }
        .content-wrapper { .ib;
          .badge { .w(72); .fs(16, 36); }
          .date-info { .fs(16); .ml(12); }
          &:nth-of-type(2) { .ml(32); }
        }
        .content-wrapper + .content-wrapper { .mt(16); }
      }
    }
    .league-progress { .wh(682, 368); .p(32, 32, 32, 40);
      > article {
        .outer { .wh(80); .p(6); .-box;
          > h2 { .wh(66); .lh(66); .fs(18); .ls(-7%); }
        }
        .p-container { .ml(32);
          .p-first { .fs(18); .medium; }
          .p-second {
            > strong { .fs(24); .bold; .mr(8); }
            > em { .fs(14); .ib; }
          }
        }
        &:nth-of-type(1) { .mb(32); }
        &:nth-of-type(2) { .mb(32); }
      }
      .btn-go-landing { .rt(32, 32); }
    ;
      [image-asset] {
        &:nth-of-type(1) { .lt(74, 120); }
        &:nth-of-type(2) { .lt(74, 232); }
      }
    }
  }

  @media (@ds-up) {
    .mt(58); .mb(104);
    flex-direction: row;
    .league-intro { .wh(471, 470); .ib; .mb(0);
      [wari-wari-main-symbol] { .block; margin-left: auto; margin-right: auto; .mb(28);
        .content-wrapper {
          .badge { .w(72); .fs(16, 36); }
        }
      }
    }
    .league-progress { margin-left: auto; .mt(104); }
  }
}
</style>
