<template>
  <div>
    <HomeHeader />
    <OfficialLeague is-tab :officialLeagueData="officialLeagueData" :step="getStep" :ended="ended">
      <template #detail>
        <WariWariDetail />
      </template>
      <template #hero>
        <WariWariHero />
      </template>
      <template #gameInfo>
        <WariWariGameInfo />
      </template>
    </OfficialLeague>
    <client-only><MyInfo /></client-only>
  </div>
</template>

<script>
import _ from 'lodash';
import HomeHeader from '@/views/components/gameHome/HomeHeader.vue';
import OfficialLeague from '@/views/components/gameHome/OfficialLeague.vue';
import MyInfo from '@/views/components/gameHome/MyInfo.vue';
import WariWariDetail from '@/views/components/gameHome/wr/WariWariDetail.vue';
import WariWariHero from '@/views/components/gameHome/wr/WariWariHero.vue';
import WariWariGameInfo from '@/views/components/gameHome/wr/gameInfo/WariWariGameInfo.vue';
import officialLeagueData from '@/data/official-league/officialLeagueData';
import meta from '@/data/meta/index';

export default {
  name: 'WrHome',
  data: () => ({
    ended: false,
  }),
  components: {
    HomeHeader,
    OfficialLeague,
    MyInfo,
    WariWariDetail,
    WariWariHero,
    WariWariGameInfo,
  },
  computed: {
    base() {
      return this.$store.state.baseInfo;
    },
    officialLeagueData() {
      return officialLeagueData[this.$route.params.id];
    },
    officialLeagueDataStepTime() {
      return _.get(this.officialLeagueData, 'stepTime', []);
    },
    getStep() {
      // const today = millisecond(new Date());
      // return Number(`${_.findIndex(this.officialLeagueDataStepTime, (d) => millisecond(d) > today)}`);
      return 2;
    },
  },
  methods: {
    toInit() {
      setTimeout(() => {
        const target = document.querySelector('.league');
        this.$scroll.scrollMove(target.offsetTop + (this.matchedMediaDevice === 'M' ? 450 : 700), 1000);
      }, 200);
    },
  },
  mounted() {
    if(this.$route.query.page && this.$route.query.page === 'arena') this.toInit();
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>
